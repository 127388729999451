import "bootstrap";
import "slick-carousel";

(function ($) {
  // On Dom Ready
  $(function () {
    // Toggle mobile menu
    $(".menu-open").on("click", function () {
      $(".mobile-menu-wrapper").addClass("active");
      $(this).addClass("is-active");
      $("body").addClass("noscroll");
    });
    $(".menu-close").on("click", function () {
      $(".mobile-menu-wrapper").removeClass("active");
      $(".menu-open").removeClass("is-active");
      $("body").removeClass("noscroll");
    });

    $(".disability-champions .the-carousel").slick({
      autoplay: true,
      autoplaySpeed: 3000,
      dots: false,
      arrows: false,
      infinite: true,
      speed: 700,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: false,
      rows: 0,
    });

    $(".featured-employers .the-carousel").slick({
      autoplay: true,
      autoplaySpeed: 3000,
      dots: false,
      arrows: false,
      infinite: true,
      speed: 700,
      slidesToShow: 3,
      slidesToScroll: 3,
      adaptiveHeight: false,
      rows: 0,
      vertical: true,
      responsive: [
        {
          breakpoint: 780,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
      ],
    });

    $(".employer-of-week .the-carousel").slick({
      autoplay: false,
      autoplaySpeed: 3000,
      dots: false,
      arrows: false,
      infinite: true,
      speed: 700,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: false,
      rows: 0,
    });

    $(".disability-confident .the-carousel").slick({
      autoplay: true,
      autoplaySpeed: 3000,
      dots: false,
      arrows: false,
      infinite: true,
      speed: 700,
      slidesToShow: 3,
      slidesToScroll: 3,
      adaptiveHeight: false,
      rows: 0,
      responsive: [
        {
          breakpoint: 579,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });

    $(".our-clients .the-carousel").slick({
      autoplay: true,
      autoplaySpeed: 3000,
      dots: false,
      arrows: false,
      infinite: true,
      speed: 700,
      slidesToShow: 3,
      slidesToScroll: 3,
      adaptiveHeight: false,
      rows: 0,
      responsive: [
        {
          breakpoint: 780,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 579,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
  });

  // After page loaded completely
  $(window).on("load", function () {
    $(".mobile-menu-wrapper").addClass("initialized");
  });

  $(window).on("resize", function () {
    $(".menu-close").trigger("click");
  });
})(jQuery);
